import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { StyledPersonIcon } from '@containers/App/containers/Messaging/components/MessagesList/styles';
import { getMessagingDate, getMessagingTime } from '@utils/timeHelpers';
import {
  Wrapper,
  MessageWrapper,
  StyledAvatar,
  Message,
  NewMessagesIndicator,
  SeenIndicator,
  StyledCheckmarkIcon,
  TimeDisplay,
  DateSeparator,
} from './styles';

const formatTime = (timestamp) => {
  if (!timestamp) return '';
  try {
    return getMessagingTime(timestamp);
  } catch (error) {
    return '';
  }
};

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  try {
    // Check if date is today
    const date = new Date(parseInt(timestamp, 10));
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      return 'Today';
    }
    if (
      date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate()
    ) {
      return 'Yesterday';
    }
    return getMessagingDate(timestamp);
  } catch (error) {
    return '';
  }
};

const parsePosts = (posts, users) => {
  const selfUser = _.find(users, { self: true });
  const otherUsers = _.compact(
    users.map((user) => {
      if (user.self) return null;
      return user.id;
    }),
  );
  const parsedPosts = posts.map((post) => {
    const unread = !post.self && !post.seenBy?.includes(selfUser.id);
    const seen = selfUser.isMentor && _.difference(otherUsers, post.seenBy).length === 0;
    return {
      ...post,
      unread,
      seen,
    };
  });
  return parsedPosts;
};

// Split the long regex line to avoid linter errors
const linkifyText = (text) => {
  const emailRegex = /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)/gi;
  const urlRegex = /(\b(?:https?:\/\/|ftp:\/\/|www\.)[\w-]+(?:\.[\w-]+)+(?::\d+)?(?:\/\S*)?\b)/gi;
  const linkRegex = new RegExp(`${urlRegex.source}|${emailRegex.source}`, 'gi');
  const parts = [];
  let lastIndex = 0;
  let match = linkRegex.exec(text);
  while (match !== null) {
    if (match.index > lastIndex) {
      parts.push(text.slice(lastIndex, match.index));
    }
    const [fullMatch] = match;
    let linkText = fullMatch;
    let trailingPunctuation = '';
    const punctuationMatch = fullMatch.match(/[.,?!:;]+$/);
    const [punctuation = ''] = punctuationMatch || [];
    if (punctuation) {
      trailingPunctuation = punctuation;
      linkText = fullMatch.slice(0, fullMatch.length - punctuation.length);
    }
    let href = linkText;
    if (linkText.includes('@')) {
      href = `mailto:${linkText}`;
    } else if (!/^(https?:\/\/|ftp:\/\/)/i.test(linkText)) {
      href = `http://${linkText}`;
    }
    parts.push(
      <a href={href} key={lastIndex} target="_blank" rel="noopener noreferrer">
        {linkText}
      </a>,
    );
    if (trailingPunctuation) {
      parts.push(trailingPunctuation);
    }
    lastIndex = linkRegex.lastIndex;
    match = linkRegex.exec(text);
  }
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }
  return parts;
};

class ConversationArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isSameDay = (timestamp1, timestamp2) => {
    if (!timestamp1 || !timestamp2) return false;
    try {
      const date1 = new Date(parseInt(timestamp1, 10));
      const date2 = new Date(parseInt(timestamp2, 10));

      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    } catch (error) {
      return false;
    }
  };

  shouldShowDateSeparator = (post, index, posts) => {
    if (!post || !post.createdAt) return false;

    // Always show date separator for the first post
    if (index === posts.length - 1) return true;

    // Check if this post is from a different day than the previous post
    const prevPost = posts[index + 1];
    if (!prevPost || !prevPost.createdAt) return true;

    // Only show date separator if the date is different from the previous post
    return !this.isSameDay(post.createdAt, prevPost.createdAt);
  };

  render() {
    const { currentConversation, t } = this.props;
    if (_.isEmpty(currentConversation)) return null;

    const posts = parsePosts(
      currentConversation.Thread.Posts,
      currentConversation.Users,
    );
    const lastSeenIndex = posts.findIndex((post) => post.seen);
    return (
      <Wrapper>
        {posts.map((post, index) => {
          const isFirstUnread = post.unread
            && (index === posts.length - 1 || !posts[index + 1].unread);
          const isQueue = index !== posts.length - 1
            && post.self === posts[index + 1].self
            && !isFirstUnread;
          const isLastSeen = index === lastSeenIndex;
          const showDateSeparator = this.shouldShowDateSeparator(post, index, posts);

          return (
            <div key={post.id}>
              {showDateSeparator && (
                <DateSeparator>
                  <Text
                    size={TEXT_SIZE.T3}
                    textColor={TEXT_COLOR.DISABLED}
                  >
                    {formatDate(post.createdAt)}
                  </Text>
                </DateSeparator>
              )}

              {isFirstUnread && (
                <NewMessagesIndicator>
                  <Text
                    size={TEXT_SIZE.T3}
                    textColor={TEXT_COLOR.DISABLED}
                    margin="0 24px"
                  >
                    {t('Informative.Notifying.newMessages')}
                  </Text>
                </NewMessagesIndicator>
              )}

              <MessageWrapper self={post.self} isQueue={isQueue}>
                {!isQueue && (
                  post.publisherUser?.avatar
                    ? <StyledAvatar src={post.publisherUser.avatar} />
                    : <StyledPersonIcon />
                )}
                <Message self={post.self} isQueue={isQueue}>
                  <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
                    {linkifyText(post.payload.text)}
                  </Text>
                  <TimeDisplay self={post.self}>
                    <Text
                      size={TEXT_SIZE.T5}
                      textColor={TEXT_COLOR.DISABLED_LIGHT}
                    >
                      {formatTime(post.createdAt)}
                    </Text>
                  </TimeDisplay>
                </Message>
              </MessageWrapper>

              {isLastSeen && (
                <SeenIndicator>
                  <StyledCheckmarkIcon />
                  <Text
                    size={TEXT_SIZE.T5}
                    textColor={TEXT_COLOR.DISABLED_LIGHT}
                  >
                    {t('Common.Statuses.seen')}
                  </Text>
                </SeenIndicator>
              )}
            </div>
          );
        })}
      </Wrapper>
    );
  }
}

ConversationArea.propTypes = {
  currentConversation: PropTypes.object,
  t: PropTypes.func,
};

export default compose(
  withTranslation(),
)(ConversationArea);
